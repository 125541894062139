import Plausible from "plausible-tracker"
import { APP_ENV } from "../env"
import { PLAUSIBLE_CONFIG } from "../initialize/initializePlausible"

type TrackEvent = Parameters<ReturnType<typeof Plausible>["trackEvent"]>
export const trackEvent = (...event: TrackEvent) => {
  if (APP_ENV !== "production") return

  const plausible = Plausible(PLAUSIBLE_CONFIG)

  plausible.trackEvent(...event)
}
