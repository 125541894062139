import html2canvas, { type Options } from "html2canvas"
import { jsPDF, type jsPDFOptions } from "jspdf"
import { useRef, useState } from "react"

// Default options for HTML2Canvas
// With those settings download is about 22MB.
const optionsHTML2Canvas: Partial<Options> = {
  allowTaint: true, // allows using externally hosted images
  useCORS: true, // helpful when using external images
  scale: 5,
}
// Default options for JSPDF
const optionsJSPDF: jsPDFOptions = { orientation: "portrait", format: "a4" }

/**
 * Allows to download the content of a specified HTML element as a PDF file.
 * Element that has the printRef provided will first be converted to a canvas
 * (options are specified in optionsHTML2Canvas above), which then gets converted
 * to PDF (options are specified in optionsJSPDF above).
 * @param fileName Optional name for the downloaded file
 * @param optionsCanvas Optional options for HTML2Canvas. It is especially useful
 * to define `onclone` method which allows us to modify the  HTML document before
 * it gets converted to a canvas. It can be used to fix a HTML2Canvas issue where
 * some elements get shifted relative to their original positions:
 * https://github.com/niklasvh/html2canvas/issues/2042
 * @param optionsPDF Optional options for JSPDF
 */
export const useDownloadHTMLAsPDF = (
  fileName?: string,
  optionsCanvas: Partial<Options> = {},
  optionsPDF: jsPDFOptions = {}
) => {
  const [isDownloadPending, setIsDownloadPending] = useState(false)
  const printRef = useRef<HTMLDivElement>(null)

  const onDownload = () => {
    const name = fileName ?? `attensi-${new Date().toISOString()}`
    savePDF(name)
  }

  const savePDF = async (fileName: string) => {
    setIsDownloadPending(true)
    const printableElement = printRef.current

    if (!printableElement) {
      throw new Error("Printable element is not found")
    }

    const canvas = await html2canvas(printableElement, {
      ...{ ...optionsHTML2Canvas, ...optionsCanvas },
      ...{ ...optionsJSPDF, ...optionsPDF },
    })
    const data = canvas.toDataURL("image/png")

    const pdf = new jsPDF(optionsJSPDF)
    const { width, height } = pdf.getImageProperties(data)
    const pdfHeight = pdf.internal.pageSize.getHeight()
    const pdfWidth = (pdfHeight / height) * width
    // Center the image on the page
    const offsetX = pdf.internal.pageSize.getWidth() / 2 - pdfWidth / 2
    const offsetY = pdf.internal.pageSize.getHeight() / 2 - pdfHeight / 2

    pdf.addImage(data, "png", offsetX, offsetY, pdfWidth, pdfHeight)
    pdf.save(`${fileName}.pdf`)
    setIsDownloadPending(false)
  }

  return { printRef, isDownloadPending, onDownload }
}
