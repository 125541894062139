import arrowDown from "assets/icons/arrowDown.svg"
import chevronIcon from "assets/icons/chevronIcon.svg"
import completedIcon from "assets/icons/completedIcon.svg"
import informationIcon from "assets/icons/informationIcon.svg"
import lock from "assets/icons/lock.svg"
import { ReactComponent as PlayButton } from "assets/icons/playButton.svg"
import starModalEmpty from "assets/icons/starModalEmpty.svg"
import starModalFilled from "assets/icons/starModalFilled.svg"
import { useToggle } from "hooks"
import { times } from "lodash-es"
import { useWindowWidth } from "materia"
import type { PropsWithChildren, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { modifiers, sanitizeHtml } from "ui-tools"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import { ProductModal } from ".."
import isDiplomasEnabled from "../../../isDiplomasEnabled"
import { usePageTitle } from "../../../tools/usePageTitle"
import { Badge } from "../../Badge"
import { type Data, type Product } from "./gql"
import { DeadlineStatus, DiplomaStatus } from "./Status"
import s from "./styles.module.scss"

export const ProductModalMain = ({
  children,
  initialIsReadMoreVisible,
  data,
}: {
  children: ReactNode
  initialIsReadMoreVisible?: boolean
  data: Data
}) => {
  usePageTitle(data.title)

  const [isReadMoreVisible, toggleReadMore] = useToggle(
    initialIsReadMoreVisible ?? false
  )

  const { isLocked } = data

  return (
    <ProductModal.Main>
      <div
        className={modifiers(s, "productModal", { isLocked })}
        aria-live="polite"
      >
        <Primary
          product={data}
          isReadMoreVisible={isReadMoreVisible}
          toggleReadMore={toggleReadMore}
          children={children}
        />
        <ReadMore
          product={data}
          isReadMoreVisible={isReadMoreVisible}
          toggleReadMore={toggleReadMore}
        />
      </div>
    </ProductModal.Main>
  )
}

const Primary = ({
  product,
  isReadMoreVisible,
  toggleReadMore,
  children,
}: {
  product: Product
  isReadMoreVisible: boolean
  toggleReadMore: () => void
  children: ReactNode
}) => {
  const { progress, isLocked } = product

  const isOneStarProduct = progress.starsMax === 1
  const isFiveStarProduct = progress.starsMax === 5

  const hasDiploma = Boolean(product?.diplomaConfiguration)
  // TODO [WEB-16980] Lock diplomas behind a query param
  const shouldShowDiplomas = hasDiploma && isDiplomasEnabled

  const deadlineStatus = product?.deadlineOccurrence?.status
  // We need this atm as "white spacing" is not build into the product modal layout. Recommended in the future.
  const shouldShowDeadlineStatus =
    deadlineStatus && deadlineStatus !== "upcoming"

  return (
    <div className={modifiers(s, "primary", { isVisible: !isReadMoreVisible })}>
      <div className={s.badges}>
        {progress.certified && <CompletedIcon />}
        {isOneStarProduct && <ProductType product={product} />}
        {progress.mandatory && <Mandatory />}
      </div>
      <Image product={product} />
      <div className={s.bottom}>
        <Stars product={product} />
        <Title product={product} />
        {isFiveStarProduct && <Score product={product} />}
        <Description product={product} toggleReadMore={toggleReadMore} />

        {shouldShowDiplomas && <DiplomaStatus product={product} />}
        {!shouldShowDiplomas && shouldShowDeadlineStatus && (
          <DeadlineStatus product={product} />
        )}

        {isLocked ? (
          <Locked product={product} />
        ) : (
          <Buttons>{children}</Buttons>
        )}
      </div>
    </div>
  )
}

const CompletedIcon = () => (
  <img
    className={s.completedIcon}
    src={completedIcon}
    alt="Product status: Completed"
  />
)

const ProductType = ({ product }: { product: Product }) => {
  const { t } = useTranslation()
  const { productType } = product
  const productTypeText = t(`product.PRODUCT_TYPES.${productType}`)
  return <Badge title={productTypeText} />
}

const Mandatory = () => {
  const { t } = useTranslation()
  return <Badge title={t("user.MANDATORY")} />
}

const Image = ({ product }: { product: Product }) => {
  const { image } = product
  return <img className={s.image} src={image} alt="" />
}

const Stars = ({ product }: { product: Product }) => {
  const { starsCount, starsMax } = product.progress

  const elements = times(starsMax, (index) => {
    const isFilled = index < starsCount
    const src = isFilled ? starModalFilled : starModalEmpty
    return <img src={src} alt="" key={index} />
  })

  return <div className={s.stars}>{elements}</div>
}

const Title = ({ product }: { product: Product }) => {
  const { title } = product
  return <h2 className={s.title}>{title}</h2>
}

const Score = ({ product }: { product: Product }) => {
  const { t } = useTranslation()

  const { progress } = product
  const { isBigWindow } = useWindowWidth()

  if (isBigWindow) return null

  if (progress.highScore === 0) return

  return (
    <div className={s.score}>{`${progress.highScore} ${t(
      "product.SCORE"
    )}`}</div>
  )
}

const Description = ({
  product,
  toggleReadMore,
}: {
  product: Product
  toggleReadMore: () => void
}) => {
  const { t } = useTranslation()

  const { descriptionLong } = product
  const description = descriptionLong ?? ""

  const shortDescription = trimDescription(description)
  const isButtonVisible = shortDescription.length < description.length

  return (
    <p className={s.description}>
      <span
        dangerouslySetInnerHTML={sanitizeHtml(shortDescription, "allow-links")}
      />
      {isButtonVisible && (
        <button
          className={s.description__showMore}
          onClick={toggleReadMore}
          aria-label="Read more about this product"
        >
          <span aria-hidden="true">...{t("product.PRODUCT_READ_MORE")}</span>
        </button>
      )}
    </p>
  )
}

const Locked = ({ product }: { product: Product }) => {
  const { t } = useTranslation()

  const lockedJourney = product.lockedBy.nodes[0]

  if (!lockedJourney) {
    throw new Error("Locked product must have a locked journey")
  }

  return (
    <div className={s.locked}>
      <button className={s.locked__button} disabled>
        <img src={lock} alt="" />
        {t("product.LOCKED")}
        <div />
      </button>
      <div className={s.locked__description}>
        {t("product.LOCKED_COMPLETE_1")}
        <div className={s.locked__title}>{lockedJourney.title}</div>
        {t("product.LOCKED_COMPLETE_2")}
      </div>
    </div>
  )
}

const Buttons = ({ children }: PropsWithChildren) => (
  <ProductModal.ActionButtons>
    <div className={s.buttons}>{children}</div>
  </ProductModal.ActionButtons>
)

type PlayLinkProps = FiestaLinkProps & {
  children: ReactNode
  iconVariant?: "play" | "download"
  linkVariant?: "green" | "white"
}
const PlayLink = ({
  iconVariant = "play",
  linkVariant = "green",
  children,
  ...props
}: PlayLinkProps) => (
  <FiestaLink className={modifiers(s, "playLink", linkVariant)} {...props}>
    {iconVariant === "play" ? (
      <PlayButton className={s.playLink__icon} />
    ) : (
      <img className={s.playLink__icon} src={arrowDown} alt="" />
    )}
    {children}
  </FiestaLink>
)

const HelpIcon = ({ onClick }: { onClick: () => void }) => (
  <button onClick={onClick} className={s.helpIcon}>
    <img src={informationIcon} alt="Help" />
  </button>
)

const LanguageDropdown = ({ children }: PropsWithChildren) => (
  <div className={s.languageDropdown}>{children}</div>
)

const NotSupported = ({
  title,
  supportedBy,
  platforms,
}: {
  title: string
  supportedBy: string
  platforms: string
}) => (
  <div className={s.notSupported}>
    <div className={s.notSupported__title}>{title}</div>
    <div className={s.notSupported__supportedBy}>
      {supportedBy}{" "}
      <span className={s.notSupported__platforms}>{platforms}</span>
    </div>
  </div>
)

const ReadMore = ({
  product,
  toggleReadMore,
  isReadMoreVisible,
}: {
  product: Product
  toggleReadMore: () => void
  isReadMoreVisible: boolean
}) => {
  const { title, descriptionLong } = product
  const description = descriptionLong ?? ""

  return (
    <div className={modifiers(s, "readMore", { isVisible: isReadMoreVisible })}>
      <header className={s.readMore__header}>
        <button
          className={s.readMore__headerBackButton}
          onClick={toggleReadMore}
        >
          <img src={chevronIcon} alt="Go back to product" />
        </button>
        <h2 className={s.readMore__headerTitle}>{title}</h2>
      </header>
      <div className={s.readMore__description}>
        <span
          dangerouslySetInnerHTML={sanitizeHtml(description, "allow-links")}
        />
      </div>
    </div>
  )
}

ProductModalMain.PlayLink = PlayLink
ProductModalMain.LanguageDropdown = LanguageDropdown
ProductModalMain.NotSupported = NotSupported
ProductModalMain.HelpIcon = HelpIcon

const trimDescription = (description: string) => {
  const MAX_LENGTH = 175
  const SPACE = " "
  const words = description.split(SPACE)

  let trimmedText = words[0] ?? ""
  for (const word of words.slice(1)) {
    const isTooLong = trimmedText.length + word.length > MAX_LENGTH
    if (isTooLong) break
    trimmedText += SPACE + word
  }

  return trimmedText
}
