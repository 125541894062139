import checkIconCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import starFilled from "assets/icons/starFilled.svg"
import { useGraphqlQuery } from "graphql-mock"
import { Card } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { ErrorPopup, FiestaImage, FiestaLink } from "utility-components"
import { HiddenHeader } from "../../../components/HiddenHeader"
import { query, type Journey, type Progress } from "./gql"
import { JourneyTeaserLoading } from "./loading"
import s from "./styles.module.scss"

export const JourneyTeaser = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {})
  const { t } = useTranslation()

  if (isPending) return <JourneyTeaserLoading />
  if (error) return <ErrorPopup error={error} />

  const { nextJourney } = data
  if (!nextJourney) return

  const { title, progress } = nextJourney

  return (
    <Card borderRadius="24" transparent>
      <HiddenHeader title={t("journey.CONTINUE_IN_JOURNEY")} tag="h2" />
      <FiestaLink className={modifiers(s, "teaser")} to="/journey">
        <Image journey={nextJourney} />
        <div className={s.gradient}>
          <h3 className={s.title}>{title}</h3>
          {progress && <Stars progress={progress} />}
          {progress && <Certified progress={progress} />}
        </div>
      </FiestaLink>
    </Card>
  )
}

const Image = ({ journey }: { journey: Journey }) => {
  const { backgroundImageUrl } = journey
  return (
    <FiestaImage
      className={s.image}
      src={backgroundImageUrl ?? ""}
      alt=""
      sizes="(width <= medium) 100vw, 650px"
      height={175}
    />
  )
}

const Stars = ({ progress }: { progress: Progress }) => {
  const { starsCount, starsMax } = progress

  return (
    <div className={s.stars} aria-hidden>
      <img className={s.stars__icon} src={starFilled} alt="" />
      {starsCount}/{starsMax}
    </div>
  )
}

const Certified = ({ progress }: { progress: Progress }) => {
  const { certifiedCount, certifiedMax } = progress

  return (
    <div className={s.certified} aria-hidden>
      <img
        src={checkIconCircleGreen}
        className={s.certified__icon}
        alt="Completed"
      />
      {certifiedCount}/{certifiedMax}
    </div>
  )
}
